import React from 'react';

import CareersCard from '@elements/CareersCard';
import RichText from '@elements/RichText';
import Left from '@svg/icons/cta_shape_left.svg';
import Right from '@svg/icons/cta_shape_right.svg';
import { getClasses } from '@utils';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import './style.css';

type CareersTwoColsSectionProps = {
  module: {
    __typename: string;
    center: boolean;
    items: {
      __typename: string;
      content: {
        raw: string;
      };
      image: {
        gatsbyImageData?: IGatsbyImageData;
        file?: {
          contentType: string;
          fileName: string;
          url: string;
        };
      };
    }[];
    backgroundImage: boolean;
    bottomSection: {
      raw: string;
      references: object | any;
    }
  };
};

const CareersTwoColsSection = ({ module }: CareersTwoColsSectionProps): JSX.Element => {
  const itemRender = (item, i) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (item.__typename) {
      case 'ContentfulCareersCard':
        return <CareersCard key={i} card={item} />;
      default:
        return null;
    }
  };

  const middleItem = Math.round(module.items.length / 2);
  const firstColItems = module.items.slice(0, middleItem);
  const secondColItems = module.items.slice(middleItem);
  const commonClasses = 'flex flex-col justify-center lg:w-1/2';
  const leftClasses = getClasses(commonClasses, module.center && 'lg:pl-24 lg:pr-8');
  const rightClasses = getClasses(commonClasses, module.center && 'lg:pr-24 lg:pl-8');

  return (
    <>
      <section className='pb-24 font-mont' id="two-col-section">
        <div className='container mx-auto px-4 text-center flex flex-wrap'>
          <div className={leftClasses}>
            {firstColItems.map((item, i) => itemRender(item, i))}
          </div>
          <div className={rightClasses}>
            {secondColItems.map((item, i) => itemRender(item, i))}
          </div>
        </div>
      </section>
      {!module.backgroundImage 
        ? (
          <section className='career-positions-section' id='bottom-section-without-background-image'>
            <div className='container mx-auto px-8 sm:px-4'>
              <div className='positions-top-text mt-6 lg:mt-0 text-center text-xl lg:text-3xl font-bold lg:w-1/2 mx-auto'>
                {module?.bottomSection && <RichText bodyRichText={module.bottomSection} />}
              </div>
            </div>
          </section>
        ) 
        : (
          <section className='careers-banner-section' id='bottom-section-with-background-image'>
            <Left className='hidden lg:block absolute h-full top-0 left-0' />
            <div className='container mx-auto px-8 sm:px-4 text-center'>
              <div className='careers-banner-body'>
                {module?.bottomSection && <RichText bodyRichText={module.bottomSection} />}
              </div>{' '}
            </div>
            <Right className='absolute h-full top-0 right-0 hidden sm:block' />
          </section>
        )}
    </>
  );
};

export default CareersTwoColsSection;
