import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import CaretRight from '@svg/icons/caret-right.svg';
import { getClasses } from '@utils';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type CareersCardProps = {
  card: {
    __typename: string;
    content: {
      raw: string;
    };
    image: {
      gatsbyImageData?: IGatsbyImageData;
      file?: {
        contentType: string;
        fileName: string;
        url: string;
      };
    };
    background?: string;
    readMoreLink?: string;
  };
};

const Image = ({ image }) => {
  const title = image?.title || '';
  if (image.file.contentType === 'image/svg+xml') return <img alt={title} src={image.file.url} />;
  return <GatsbyImage alt={title} image={image.gatsbyImageData} />;
};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      // eslint-disable-next-line react/destructuring-assignment
      children?.toString().trim() === '' ? null : <p className='font-med leading-9'>{children}</p>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className='text-3xl my-6 font-medium'>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className='text-22px mt-8 mb-4 font-medium'>{children}</h3>,
  },
};

const CareersCard = ({ card }: CareersCardProps): JSX.Element => {

  const selectMainColor = {
    white: 'bg-rt-white text-rt-medium-gray mt-6',
    purple: 'bg-rt-purple text-white h-full sm:p-10 lg:p-20 text-center',
    green: 'bg-rt-green text-white h-full sm:p-10 lg:p-20 text-center',
  };

  const mainClasses = getClasses(
    'w-full text-left p-4 flex flex-col',
    selectMainColor[card?.background],
  );

  const selectWrapperColor = {
    white: 'w-32',
    purple: 'mx-auto',
    green: 'mx-auto',
  };

  const imageWrapperClasses = getClasses(
    selectWrapperColor[card?.background]
  );

  return (
    <div className={mainClasses}>
      <div className={imageWrapperClasses}>
        <Image image={card.image} />
      </div>
      <div>
        {documentToReactComponents(JSON.parse(card.content?.raw), options)}
      </div>
      {
        card?.readMoreLink && (
          <div className='pt-10 m-auto mb-0'>
            <a className='flex items-center font-semibold' href={card.readMoreLink}>
              Read more
              <span className='bg-white rounded-full overflow-hidden ml-3 p-2'>
                <CaretRight className='pl-px w-3 h-3 text-black' />
              </span>
            </a>
          </div>
        )
      }
    </div>
  );
};

export default CareersCard;
