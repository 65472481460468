import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Button from '@elements/Button';
import CaretRight from '@svg/icons/caret-right.svg';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type CareersHeroProps = {
  module: {
    __typename: string;
    title: string;
    heading: {
      raw: string;
    };
    content: {
      raw: string;
      references?: {
        __typename: string;
        contentful_id: string;
        title: string;
        ctaLink: string;
        position?: string;
        size?: string;
        color?: string;
      };
    };
    backgroundColor?: string;
    backgroundGradient?: boolean;
    sideImage?: {
      gatsbyImageData?: IGatsbyImageData;
      file?: {
        contentType: string;
        fileName: string;
        url: string;
      };
    };
    imagePosition?: boolean;
    textColor?: boolean;
    isMainHero?: boolean;
    readMoreLink?: string;
  };
};

type NodeProps = {
  nodeType: string;
  content: [];
  data: {
    target: {
      sys: {
        id: string;
        linkType: string;
        type: string;
      };
    };
  };
};

type ButtonCTAProps = {
  __typename: string;
  contentful_id: string;
  title: string;
  ctaLink: string;
  color: string;
  position: string;
};

type ContentfulItemProps = {
  __typename?: string;
  contentful_id?: string;
  title?: string;
  file?: {
    title: string;
    url: string;
    contentType: string;
  };
  gatsbyImageData?: IGatsbyImageData;
  ctaLink?: string;
  color?: string;
  position?: string;
  size?: string;
};

type ReferencesProps = Array<ButtonCTAProps | any>;

const entryEmbed = (node: NodeProps, references: ReferencesProps): JSX.Element => {
  if (node?.data.target.sys.linkType === 'Entry') {
    const found: ContentfulItemProps = references?.find(({ contentful_id }) => contentful_id === node?.data.target.sys.id);
    // eslint-disable-next-line no-underscore-dangle
    switch (found?.__typename) {
      case 'ContentfulButtonCta': {
        const { title, ctaLink, color } = found;

        return (
          <Button childClassName={`!rounded-full !px-8 py-0 mt-14 btn--${color}`} href={ctaLink}>
            {title}
          </Button>
        );
      }
      default:
        return null;
    }
  }
  return null;
};

const titleOptions = {
  renderNode: {
    // eslint-disable-next-line react/destructuring-assignment
    [BLOCKS.PARAGRAPH]: (node, children) => (children?.toString().trim() === '' ? null : <p>{children}</p>),
  },
};

const contentOptions = (references, paragraphColor, headingColor, headingSize) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      // eslint-disable-next-line react/destructuring-assignment
      children?.toString().trim() === '' ? null : (
        <p className={`text-17px lg:text-19px font-med leading-9 mt-4  ${paragraphColor}`}>{children}</p>
      ),
    [BLOCKS.HEADING_1]: (node, children) => <h1 className={`font-bold ${headingSize} ${headingColor}`}>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className={`font-bold ${headingSize} ${headingColor}`}>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className={`font-bold ${headingSize} ${headingColor}`}>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className={`font-bold ${headingSize} ${headingColor}`}>{children}</h4>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => entryEmbed(node, references),
  },
});

const Image = ({ image }) => {
  const title = image?.title || '';
  if (image?.file?.contentType === 'image/svg+xml') return <img alt={title} src={image?.file?.url} />;

  return <GatsbyImage alt={title} image={image?.gatsbyImageData} />;
};

const CareersHero = ({ module }: CareersHeroProps): JSX.Element => {
  const { sideImage, backgroundColor, backgroundGradient, content, imagePosition, heading, textColor, readMoreLink, isMainHero } =
    module;
  const paragraphColor = textColor ? 'text-rt-medium-gray' : 'text-white';
  const headingColor = textColor ? 'text-black' : 'text-white';
  const headingSize = isMainHero ? 'text-3xl lg:text-4.5xl' : 'text-3xl lg:text-3.6xl leading-9';

  return (
    <section
      className='pt-12 pb-24 lg:py-36 font-mont'
      style={backgroundGradient ? { background: `linear-gradient(${backgroundColor},#fff)` } : { backgroundColor }}
    >
      <div className='container mx-auto flex flex-wrap px-4'>
        <div className='w-full lg:w-2/5'>
          {heading && (
            <div className={`flex items-center ${isMainHero ? 'justify-center lg:justify-start' : ''}`}>
              <div className='w-8 border-2 border-rt-rating-strong h-0 border-solid' />
              <div className='text-lg text-rt-rating-strong uppercase font-bold ml-2'>
                {documentToReactComponents(JSON.parse(heading?.raw), titleOptions)}
              </div>
            </div>
          )}
          {content && (
            <div className={`mt-4 ${isMainHero ? 'text-center lg:text-left' : ''}`}>
              {documentToReactComponents(
                JSON.parse(content?.raw),
                contentOptions(content?.references, paragraphColor, headingColor, headingSize)
              )}
            </div>
          )}
          {
            readMoreLink && (
              <div className='pt-10 m-auto mb-0'>
                <a className='flex items-center font-semibold' href={readMoreLink}>
                  Read more
                  <span className='bg-white rounded-full overflow-hidden ml-3 p-2'>
                    <CaretRight className='pl-px w-3 h-3 text-black' />
                  </span>
                </a>
              </div>
            )
          }
        </div>
        <div className={`w-full lg:w-3/5 mt-10 lg:mt-0 ${imagePosition ? 'lg:order-first' : ''}`}>
          <Image image={sideImage} />
        </div>
      </div>
    </section>
  );
};

export default CareersHero;
