import React from 'react';

import { graphql } from 'gatsby';

import CareersHero from '../components/sections/CareersHero';
import CareersTwoColsSection from '../components/sections/CareersTwoColsSection';

const CareersPageTemplate = ({ data: { contentfulCareers }, pageContext: { allJobPosts } }) => {
  const moduleRender = (module, i) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (module.__typename) {
      case 'ContentfulCareersHero':
        return <CareersHero key={i} module={module} />;
      case 'ContentfulCareersTwoColsSection':
        return <CareersTwoColsSection key={i} module={module} />;
      default:
        return null;
    }
  };

  return contentfulCareers.modules.map((module, i) => moduleRender(module, i));
};

export default CareersPageTemplate;

export const pageQuery = graphql`
  query ($contentful_id: String!) {
    contentfulCareers(contentful_id: { eq: $contentful_id }) {
      modules {
        ...CareersHero
        ...CareersTwoColsSection
      }
    }
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
    seo: contentfulCareers(contentful_id: { eq: $contentful_id }) {
      __typename
      title
      slug
      description {
        description
      }
    }
  }
`;
